import FingerprintJS from '@fingerprintjs/fingerprintjs';

export const analyticsLabels = {
    BotSignature: 'User-Agent contains bot signature',
    Headless: 'Detected Headless Browser',
    NavProps: 'Suspicious navigator properties',
    PerfAPI: 'Unusual Performance API behavior',
    NoInteraction: 'No real user interaction detected',
};

const botPatterns = [
    /bot/i,
    /crawl/i,
    /spider/i,
    /slurp/i,
    /mediapartners/i,
    /facebook/i,
    /baiduspider/i,
    /yandex/i,
    /duckduckbot/i,
    /bingbot/i,
    /googlebot/i,
];

class BotDetector {
    constructor() {
        this.isBot = null;
        this.visitorId = null;
        this.failedChecks = [];
    }

    async detectBot() {
        if (window.__PUPPETEER_PREBUILD_MODE__) {
            this.isBot = false;
            this.failedChecks = [];
            return;
        }

        const failedChecksList = [];

        const fp = await FingerprintJS.load();
        const { visitorId, components, confidence } = await fp.get();

        this.visitorId = visitorId;

        const isBotByUserAgent = () => {
            const isBot = botPatterns.some((pattern) =>
                pattern.test(navigator.userAgent)
            );

            if (isBot) failedChecksList.push('BotSignature');
            return isBot;
        };

        const isHeadlessBrowser = () => {
            const userAgent = navigator.userAgent.toLowerCase();

            const isHeadless =
                navigator.webdriver ||
                /headlesschrome/.test(userAgent) ||
                /headlessfirefox/.test(userAgent) ||
                /phantomjs/.test(userAgent) ||
                (!window.outerWidth && !window.outerHeight) ||
                window.screen.width === 0 ||
                window.screen.height === 0;

            if (isHeadless) failedChecksList.push('Headless');
            return isHeadless;
        };

        const isNavigatorSuspicious = () => {
            const missingLanguages =
                !navigator.languages || navigator.languages.length === 0;
            const missingHardwareConcurrency =
                !navigator.hardwareConcurrency ||
                navigator.hardwareConcurrency < 1;
            const isWebDriver = navigator.webdriver === true;

            const isSuspicious =
                missingLanguages || missingHardwareConcurrency || isWebDriver;

            if (isSuspicious) failedChecksList.push('NavProps');
            return isSuspicious;
        };

        const isPerformanceSuspicious = () => {
            const isSuspicious =
                !performance.getEntriesByType('navigation').length;

            if (isSuspicious) failedChecksList.push('PerfAPI');
            return isSuspicious;
        };

        const isFontsSuspicious = () => {
            const fontsAvailable = components.fonts?.value?.length > 0;
            const isSuspicious = !fontsAvailable;

            if (isSuspicious) failedChecksList.push('Fonts');
            return isSuspicious;
        };

        const isAudioSuspicious = () => {
            const hasAudio = components.audio?.value !== 0;
            const isSuspicious = !hasAudio;

            if (isSuspicious) failedChecksList.push('Audio');
            return isSuspicious;
        };

        const isLowConfidence = () => {
            const lowConfidence = confidence.score < 0.85;

            if (lowConfidence)
                failedChecksList.push(`Confidence${confidence.score}`);
            return lowConfidence;
        };

        const botUserAgent = isBotByUserAgent();
        const headlessBrowser = isHeadlessBrowser();
        const suspiciousNavigator = isNavigatorSuspicious();
        const suspiciousPerformance = isPerformanceSuspicious();
        const suspiciousFonts = isFontsSuspicious();
        const suspiciousAudio = isAudioSuspicious();
        const confidenceScore = isLowConfidence();

        const totalFailedChecks = [
            botUserAgent,
            headlessBrowser,
            suspiciousNavigator,
            suspiciousPerformance,
            suspiciousFonts,
            suspiciousAudio,
            confidenceScore,
        ].filter(Boolean).length;

        this.failedChecks = failedChecksList;
        this.isBot = totalFailedChecks >= 2;
    }
}

export default BotDetector;
